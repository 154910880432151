import { useApi } from "@/hooks/useApi";
import { TokenPriceFetcher } from "@/services/TokenPriceFetcher";

export interface CoinGeckoPriceProps {
  date?: string | number;
}

export function useCoinGeckoPrice(props?: CoinGeckoPriceProps) {
  const { data, loading, error } = useApi(
    async (...params: any[]) => {
      const res = await TokenPriceFetcher.instance.fetchPrice(params[0]);
      return res;
    },
    [props?.date]
  );
  return {
    data,
    loading,
    error,
  };
}
