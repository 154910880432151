import { PropsWithChildren } from "react";
import { ArrowRight } from "lucide-react";

import { Header } from "@/components/Header";
import { AnnouncementBar } from "@/components/AnnouncementBar";
import { Footer } from "@/components/Footer";
import { Button } from "@/components/ui/button";

export interface PageContainerProps {}

export function PageContainer(props: PropsWithChildren<PageContainerProps>) {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col bg-white border-b sticky top-0 z-50">
        <AnnouncementBar>
          <Button
            className="uppercase text-white"
            variant="link"
            onClick={() => window.open("https://stacks.co", "_blank")?.focus()}
          >
            learn more about stacks
            <ArrowRight className="ml-1 h-5 w-5" />
          </Button>
        </AnnouncementBar>
        <Header />
      </div>
      {props.children}
      <Footer />
    </div>
  );
}
