import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface AccountTransactionListProps extends PaginationOptions {
  address: string;
  blockHeight?: string;
  untilBlock?: string;
  includeTransfers?: boolean;
}

export function useAccountTransactions(props: AccountTransactionListProps) {
  const { accountsApi } = useContext(StacksApiContext)!;
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const apiFn = props?.includeTransfers
    ? accountsApi.getAccountTransactionsWithTransfers
    : accountsApi.getAccountTransactions;

  const { data, loading, error } = useApi(apiFn.bind(accountsApi), [
    {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      principal: props.address,
      unanchored: true,
      height: props?.blockHeight,
      until_block: props?.untilBlock,
    },
  ]);

  return {
    transactions: data?.results || [],
    total: data?.total ?? 0,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
