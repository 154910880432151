import { DollarSign } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { truncateInMiddle } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";
import { STXPriceConversion } from "@/components/STXPriceConversion";

export type TransferRowProps = {
  transfer: any;
  compact?: boolean;
  className?: string;
};

export function TransferRow({
  transfer,
  compact = false,
  className,
}: TransferRowProps) {
  const navigate = useNavigate();
  const amount = Number(transfer?.amount ?? 0) / 1_000_000;
  return (
    <CommonRow
      icon={DollarSign}
      title={
        <div className="flex flex-row items-center gap-1">
          {`Received ${Number(amount)} STX`}
          <div className="text-xs font-medium text-muted-foreground">
            <STXPriceConversion amount={amount} />
          </div>
        </div>
      }
      subtitle={
        transfer?.sender && (
          <Button
            variant="link"
            size="none"
            onClick={() => navigate(`/accounts/${transfer?.sender}`)}
          >
            {truncateInMiddle(transfer?.sender, 10)}
          </Button>
        )
      }
      className={className}
      onClick={() => navigate(`/transactions/${transfer?.tx_id}`)}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={transfer?.tx_id}
            displayText={
              transfer?.tx_id && truncateInMiddle(transfer?.tx_id, 10)
            }
          />
        </Badge>
      </CenteredRowCell>
    </CommonRow>
  );
}
