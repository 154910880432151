import React, { useState } from "react";
import { SearchIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { useSearch } from "@/hooks/useSearch";
import { Combobox } from "@headlessui/react";
import { SearchItem } from "@/components/SearchItem";

export function Search({ className }: { className?: string }) {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const { result } = useSearch({
    id: query,
    includeMetadata: true,
  });
  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative">
        <div
          className={cn(
            "flex items-center h-10 w-full overflow-hidden rounded-md border border-input bg-background text-sm ring-offset-background focus-within:shadow disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        >
          <SearchIcon className="mx-3 h-5 w-5" />
          <Combobox.Input
            className="flex grow bg-background py-2 pr-3 focus-visible:outline-none placeholder:text-muted-foreground"
            placeholder="Search by Address / Tx Hash / Block"
            onChange={(event: any) => setQuery(event?.target?.value)}
            displayValue={(framework: any) => framework?.label}
          />
        </div>
        {query !== "" && result && (
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-hidden">
            {!result ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              <Combobox.Option
                key={result.entity_id}
                value={result.entity_type}
                className={({ active }: any) =>
                  cn("relative cursor-default select-none")
                }
              >
                <SearchItem entity={result} />
              </Combobox.Option>
            )}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
