export function truncateInMiddle(
  data: string,
  maxLength: number = 8,
  separator: string = "..."
) {
  const length = data.length;
  if (length <= maxLength) {
    return data;
  }
  const sliceSize = Math.floor(maxLength / 2);
  return `${data.slice(0, sliceSize)}${separator}${data.slice(
    length - sliceSize,
    length
  )}`;
}
