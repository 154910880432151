import { LucideIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { CopyableText } from "@/components/CopyableText";
import { truncateInMiddle } from "@/lib";
import { cn } from "@/lib/utils";

export type BlockDisplayCellProps = {
  icon: LucideIcon;
  color: string;
  height: number;
  hash?: string;
  onClick?: () => void;
};

export function BlockDisplayCell({
  icon: Icon,
  color,
  height,
  hash,
  onClick,
}: BlockDisplayCellProps) {
  const blockHeight = Number(height).toLocaleString();
  return (
    <>
      <CenteredRowCell className="hidden md:table-cell">
        <div className={cn("p-1 rounded-full inline-block", `bg-[${color}]`)}>
          <Icon color="#FFF" className="h-4 w-4" />
        </div>
        <div
          className={cn(
            "ml-4 text-muted-foreground",
            onClick && "text-primary font-bold"
          )}
        >
          {onClick ? (
            <Button variant="link" size="none" onClick={onClick}>
              #{blockHeight}
            </Button>
          ) : (
            `#${blockHeight}`
          )}
        </div>
      </CenteredRowCell>
      {hash && (
        <CenteredRowCell className="hidden md:table-cell">
          <Badge variant="outline" className="font-medium gap-1">
            <CopyableText
              text={hash}
              displayText={truncateInMiddle(hash, 10)}
            />
          </Badge>
        </CenteredRowCell>
      )}
    </>
  );
}
