import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface BlockListProps extends PaginationOptions {
  heightOrHash?: string;
}

export function useBlocks(props?: BlockListProps) {
  const { blocksApi } = useContext(StacksApiContext)!;
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const apiFn = props?.heightOrHash
    ? blocksApi.getBlocksByBurnBlock
    : blocksApi.getBlockList;

  const { data, loading, error } = useApi(apiFn.bind(blocksApi), [
    {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      height_or_hash: props?.heightOrHash,
    },
  ]);

  return {
    blocks: data?.results || [],
    total: data?.total ?? 0,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
