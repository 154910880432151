import { useMemo } from "react";
import {
  Blocks,
  Receipt,
  FunctionSquare,
  Coins,
  Code,
  ArrowRight,
  LucideIcon,
  AlertTriangle,
  Biohazard,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";

import { truncateInMiddle, createTitleForTransaction, cn } from "@/lib";
import { CenteredRowCell } from "@/components/CenteredRowCell";
import { CommonRow } from "@/components/CommonRow";
import { BlockDisplayCell } from "@/components/BlockDisplayCell";
import { CopyableText } from "@/components/CopyableText";
import { STXPriceConversion } from "@/components/STXPriceConversion";

export type TransactionRowProps = {
  transaction: any;
  livePrice?: boolean;
  compact?: boolean;
  className?: string;
};

export function TransactionRow({
  transaction,
  compact = false,
  livePrice = true,
  className,
}: TransactionRowProps) {
  const navigate = useNavigate();
  const AddressableTo = useMemo(() => {
    switch (transaction.tx_type) {
      case "coinbase":
        return (
          <Button
            variant="link"
            className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
            onClick={() => navigate(`/accounts/${transaction.sender_address}`)}
          >
            Mined By: {truncateInMiddle(transaction.sender_address, 10)}
          </Button>
        );
      case "smart_contract":
      case "contract_call":
        return (
          <Button
            variant="link"
            className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
            onClick={() => navigate(`/accounts/${transaction.sender_address}`)}
          >
            Caller: {truncateInMiddle(transaction.sender_address, 10)}
          </Button>
        );
      case "token_transfer":
        const recipient = transaction.token_transfer?.recipient_address;
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              onClick={() =>
                navigate(`/accounts/${transaction.sender_address}`)
              }
            >
              {truncateInMiddle(transaction.sender_address, 10)}
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              onClick={() => navigate(`/accounts/${recipient}`)}
            >
              {truncateInMiddle(recipient, 10)}
            </Button>
          </div>
        );
      default:
        return (
          <Button
            variant="link"
            className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
            onClick={() => navigate(`/accounts/${transaction.sender_address}`)}
          >
            Sender: {truncateInMiddle(transaction.sender_address, 10)}
          </Button>
        );
    }
  }, [transaction]);
  const Icon: LucideIcon = useMemo(() => {
    switch (transaction.tx_type) {
      case "contract_call":
        return FunctionSquare;
      case "coinbase":
        return Coins;
      case "smart_contract":
        return Code;
      case "poison_microblock":
        return Biohazard;
      default:
        return Receipt;
    }
  }, [transaction]);
  const title = useMemo(() => {
    const success = transaction.tx_status === "success";
    const price = (transaction.token_transfer?.amount ?? 0) / 1_000_000;
    return (
      <div
        className={cn(
          "flex flex-row items-center gap-1 text-ellipsis overflow-hidden",
          !success && "text-destructive"
        )}
      >
        {!success && <AlertTriangle className="h-4 w-4" />}
        {createTitleForTransaction(transaction)}
        {transaction.tx_type === "token_transfer" && (
          <div className="text-xs font-medium text-muted-foreground">
            <STXPriceConversion
              amount={price}
              date={!livePrice && transaction.burn_block_time_iso}
            />
          </div>
        )}
      </div>
    );
  }, [transaction]);
  return (
    <CommonRow
      icon={Icon}
      title={title}
      subtitle={AddressableTo}
      date={transaction.burn_block_time_iso}
      className={className}
      onClick={() => navigate(`/transactions/${transaction?.tx_id}`)}
    >
      <CenteredRowCell className="hidden sm:table-cell">
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={transaction.tx_id}
            displayText={truncateInMiddle(transaction.tx_id, 10)}
          />
        </Badge>
      </CenteredRowCell>
      {!compact && (
        <>
          <CenteredRowCell className="hidden lg:table-cell">
            <div className="text-muted-foreground text-sm">
              Fee: {transaction.fee_rate / 1_000_000} STX
            </div>
          </CenteredRowCell>
          <BlockDisplayCell
            color="#5546FF"
            icon={Blocks}
            height={transaction.block_height}
          />
        </>
      )}
    </CommonRow>
  );
}
