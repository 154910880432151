import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";

import { Button } from "@/components/ui/button";

export type DataTablePaginationProps = {
  loading: boolean;
  total?: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  nextPage: () => void;
  prevPage: () => void;
};

export function DataTablePagination({
  loading,
  total,
  page,
  pageSize,
  setPage,
  setPageSize,
  nextPage,
  prevPage,
}: DataTablePaginationProps) {
  const pageCount = Math.ceil((total ?? 0) / pageSize);
  const canGoPrev = page !== 1 && !loading;
  const canGoNext = (page * pageSize < (total ?? 0) && !loading) || !total;

  return (
    <div className="flex w-full items-center justify-between px-2">
      {total && (
        <div className="text-sm text-muted-foreground">
          {total !== undefined && `${total?.toLocaleString()} total results`}
        </div>
      )}
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className="hidden h-8 w-10 p-0 lg:flex"
          onClick={() => setPage(Math.max(page - 10, 1))}
          disabled={page - 10 < 1}
        >
          -10
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => prevPage()}
          disabled={!canGoPrev}
        >
          <ArrowLeftIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="h-8 w-8 p-0"
          onClick={() => nextPage()}
          disabled={!canGoNext}
        >
          <ArrowRightIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className="hidden h-8 w-10 p-0 lg:flex"
          onClick={() => setPage(page + 10)}
          disabled={page + 10 > pageCount && total ? true : false}
        >
          +10
        </Button>
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center justify-center text-sm font-medium text-muted-foreground">
          {page !== undefined && `Page ${page?.toLocaleString()}`}{" "}
          {total !== undefined && `of ${pageCount?.toLocaleString()}`}
        </div>
      </div>
    </div>
  );
}
