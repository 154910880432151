import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export function useTokens(props?: PaginationOptions) {
  const { tokensApi } = useContext(StacksApiContext)!;
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    tokensApi.getFungibleTokens.bind(tokensApi),
    [
      undefined,
      undefined,
      undefined,
      (page - 1) * pageSize,
      pageSize,
      "name",
      "asc",
    ]
  );

  return {
    tokens: data?.results || [],
    total: data?.total ?? 0,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
