import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export enum TransactionType {
  coinbase = "coinbase",
  token_transfer = "token_transfer",
  smart_contract = "smart_contract",
  contract_call = "contract_call",
  poison_microblock = "poison_microblock",
  tenure_change = "tenure_change",
}

export interface UseTransactionListProps extends PaginationOptions {
  types?: TransactionType[];
  blockHash?: string;
}

export function useTransactions(props?: UseTransactionListProps) {
  const { transactionsApi } = useContext(StacksApiContext)!;
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const apiFn = props?.blockHash
    ? transactionsApi.getTransactionsByBlockHash
    : transactionsApi.getTransactionList;

  const { data, loading, error } = useApi(apiFn.bind(transactionsApi), [
    {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      unanchored: true,
      blockHash: props?.blockHash,
      type: props?.types,
    },
  ]);

  return {
    transactions: data?.results || [],
    total: data?.total ?? 0,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
