import { Blocks, Bitcoin } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { truncateInMiddle, formatCountPlural } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { BlockDisplayCell } from "@/components/BlockDisplayCell";
import { CopyableText } from "@/components/CopyableText";

export type BlockRowProps = {
  block: any;
  compact?: boolean;
  className?: string;
};

export function BlockRow({ block, compact = false, className }: BlockRowProps) {
  const navigate = useNavigate();
  const stacksBlockHeight = Number(block.height).toLocaleString();

  return (
    <CommonRow
      icon={Blocks}
      title={`#${stacksBlockHeight}`}
      date={block.burn_block_time_iso}
      className={className}
      onClick={() => navigate(`/blocks/${block?.height}`)}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={block.hash}
            displayText={truncateInMiddle(block.hash, 10)}
          />
        </Badge>
      </CenteredRowCell>
      <CenteredRowCell className="hidden lg:table-cell">
        <Badge className="font-medium">
          {formatCountPlural(
            block.txs?.length ?? 0,
            "Transaction",
            "Transactions"
          )}
        </Badge>
      </CenteredRowCell>
      {!compact && (
        <BlockDisplayCell
          color="#FC6433"
          icon={Bitcoin}
          height={block.burn_block_height}
          hash={block.burn_block_hash}
          onClick={() => {
            const parts = block?.burn_block_hash?.split("0x") ?? [];
            const hash = parts.length > 1 && parts[1];
            window.open(`https://mempool.space/block/${hash}`, "_blank");
          }}
        />
      )}
    </CommonRow>
  );
}
