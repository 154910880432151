import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface SearchProps {
  id: string;
  includeMetadata?: boolean;
}

export function useSearch(props: SearchProps) {
  const { searchApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    async (params: any) => {
      if ((params?.id?.length ?? 0) === 0) {
        return { found: false, result: null };
      }
      return await searchApi.searchById.bind(searchApi)(params as any);
    },
    [
      {
        id: props.id,
        includeMetadata: props.includeMetadata ?? false,
      },
    ]
  );

  return {
    result: data?.found && data?.result,
    loading,
    error,
  };
}
