import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export function useInfo() {
  const { infoApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(infoApi.getCoreApiInfo.bind(infoApi));

  return {
    info: data,
    loading,
    error,
  };
}
