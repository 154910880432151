import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface GetBlockProps {
  heightOrHash: string;
}

export function useBlock(props: GetBlockProps) {
  const { blocksApi } = useContext(StacksApiContext)!;

  const apiFn =
    props.heightOrHash?.indexOf("0x") > -1
      ? blocksApi.getBlockByHash
      : blocksApi.getBlockByHeight;

  const { data, loading, error } = useApi(apiFn.bind(blocksApi), [
    {
      height: props.heightOrHash,
      hash: props.heightOrHash,
      unanchored: true,
    },
  ]);

  return {
    block: data,
    loading,
    error,
  };
}
