import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface NFTMetadataProps {
  principal: string;
  tokenId: number;
}

export function useNFTMetadata({ principal, tokenId }: NFTMetadataProps) {
  const { tokensApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    tokensApi.getNftMetadata.bind(tokensApi),
    [principal, tokenId]
  );

  return {
    nft: data,
    loading,
    error,
  };
}
