import React, { useMemo } from "react";
import { Blocks, DollarSign, BarChart, LineChart } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { MetricCard } from "@/components/MetricCard";
import { DataPreviewCard } from "@/components/DataPreviewCard";
import {
  useBlocks,
  useCoinGeckoPrice,
  useSTXSupply,
  useMempoolTxStats,
} from "@/hooks";
import { formatDateToNow } from "@/lib";
import { BlockList } from "@/components/BlockList";
import { TransactionList } from "@/components/TransactionList";
import { Separator } from "@radix-ui/react-separator";

export default function App() {
  const navigate = useNavigate();
  const { data, loading: l1 } = useCoinGeckoPrice();
  const { blocks, loading: l2 } = useBlocks({ initialPageSize: 1 });
  const { supply } = useSTXSupply();
  const { stats, loading: l3 } = useMempoolTxStats();
  const loading = l1 || l2 || l3;
  const avgFees = useMemo(() => {
    const fees = {
      p25: 0,
      p50: 0,
      p75: 0,
      p95: 0,
    };

    if (!stats) return fees;

    const types = ["contract_call", "smart_contract", "token_transfer"];

    let totalCount = 0;
    types.forEach((type) => {
      totalCount += stats.tx_type_counts[type];
    });

    types.forEach((type) => {
      const proportion = stats.tx_type_counts[type] / totalCount;
      fees.p25 += stats.tx_simple_fee_averages[type].p25 * proportion;
      fees.p50 += stats.tx_simple_fee_averages[type].p50 * proportion;
      fees.p75 += stats.tx_simple_fee_averages[type].p75 * proportion;
      fees.p95 += stats.tx_simple_fee_averages[type].p95 * proportion;
    });

    fees.p25 /= 1_000_000;
    fees.p50 /= 1_000_000;
    fees.p75 /= 1_000_000;
    fees.p95 /= 1_000_000;

    return fees;
  }, [stats]);

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
        <MetricCard
          loading={loading}
          title="STX Price"
          icon={DollarSign}
          subtitle={`${
            data?.usd_24h_change > 0 ? "+" : ""
          }${data?.usd_24h_change?.toFixed(2)}% Last 24 Hours`}
        >
          ${data?.usd}
        </MetricCard>
        <MetricCard
          loading={loading}
          title="STX Market Cap"
          icon={BarChart}
          subtitle={
            supply?.unlocked_percent
              ? `${supply?.unlocked_percent}% of Tokens Unlocked`
              : "--"
          }
        >
          ${Number(data?.usd_market_cap?.toFixed(0)).toLocaleString()}
        </MetricCard>
        <MetricCard
          loading={loading}
          title="Avg. STX Transaction Fees"
          icon={LineChart}
        >
          <div className="flex flex-row grow items-center text-xs justify-between">
            <div className="flex flex-col w-20">
              <div className="font-medium uppercase text-xs">Low</div>
              <div className="text-lg">{avgFees?.p50.toFixed(2)}</div>
            </div>
            <Separator orientation="vertical" />
            <div className="flex flex-col w-20">
              <div className="font-medium uppercase text-xs">Medium</div>
              <div className="text-lg">{avgFees?.p75.toFixed(2)}</div>
            </div>
            <Separator orientation="vertical" />
            <div className="flex flex-col w-20">
              <div className="font-medium uppercase text-xs">High</div>
              <div className="text-lg">{avgFees?.p95.toFixed(2)}</div>
            </div>
          </div>
        </MetricCard>
        <MetricCard
          loading={loading}
          title="Latest Block"
          icon={Blocks}
          subtitle={`${
            blocks?.[0]?.txs?.length
          } Transactions • ${formatDateToNow(
            blocks?.[0]?.burn_block_time_iso
          )}`}
        >
          #{Number(blocks?.[0]?.height).toLocaleString()}
        </MetricCard>
      </div>
      <div className="flex grow flex-col xl:flex-row gap-4">
        <div className="xl:w-1/2">
          <DataPreviewCard
            title="Latest Transactions"
            actionTitle="View All Transactions"
            onClick={() => navigate("/transactions")}
          >
            <TransactionList compact={true} />
          </DataPreviewCard>
        </div>
        <div className="xl:w-1/2">
          <DataPreviewCard
            title="Latest Blocks"
            actionTitle="View All Blocks"
            onClick={() => navigate("/blocks")}
          >
            <BlockList compact={true} />
          </DataPreviewCard>
        </div>
      </div>
    </div>
  );
}
