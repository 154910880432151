import { useCoinGeckoPrice } from "@/hooks/useCoinGeckoPrice";

export type STXPriceConversionProps = {
  amount: number;
  date?: string | number;
  className?: string;
};

export function STXPriceConversion({
  amount,
  date,
  className,
}: STXPriceConversionProps) {
  const { data } = useCoinGeckoPrice({ date });
  return (
    <span className={className}>
      (${Number(data?.usd * amount)?.toLocaleString()} USD)
    </span>
  );
}
