import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import { PageContainer } from "./PageContainer";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "spring",
  stiffness: 100,
};

export function AnimatedLayout() {
  const { pathname } = useLocation();
  return (
    <PageContainer>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageContainer>
  );
}
