import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface GetSmartContractProps {
  id: string;
}

export function useSmartContract(props: GetSmartContractProps) {
  const { smartContractsApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    smartContractsApi.getContractById.bind(smartContractsApi),
    [
      {
        contractId: props.id,
        unanchored: true,
      },
    ]
  );

  return {
    contract: data,
    loading,
    error,
  };
}
