import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export function useSTXSupply() {
  const { infoApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(infoApi.getStxSupply.bind(infoApi), [
    { height: undefined },
  ]);

  return {
    supply: data,
    loading,
    error,
  };
}
