import { Table, TableBody } from "@/components/ui/table";

import { useContractEvents } from "@/hooks";
import { EventRow } from "@/components/EventRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "./SkeletonRow";

export type EventListProps = {
  contractId: string;
  paginate?: boolean;
  compact?: boolean;
};

export function EventList({ compact, paginate, contractId }: EventListProps) {
  const {
    events,
    loading,
    page,
    pageSize,
    changePageSize,
    goToPage,
    prevPage,
    nextPage,
  } = useContractEvents({
    contractId: contractId,
  });
  return (
    <div>
      {!loading && events?.length === 0 && (
        <div className="flex w-full p-4 text-center items-center justify-center">
          No Results Found
        </div>
      )}
      {(loading || events?.length > 0) && (
        <Table>
          <TableBody>
            {loading
              ? Array.from({ length: 10 }).map((_, i) => (
                  <SkeletonRow
                    icon={true}
                    title={true}
                    subtitle={true}
                    date={false}
                    otherCols={1}
                    key={i}
                  />
                ))
              : null}
            {!loading && events.map((event: any) => <EventRow event={event} />)}
          </TableBody>
        </Table>
      )}
      {paginate && (
        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            loading={loading}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </div>
      )}
    </div>
  );
}
