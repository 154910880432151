import { ArrowRight, FunctionSquare, Blocks } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useTransaction } from "@/hooks/useTransaction";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { TransactionEventsCard } from "@/components/TransactionEventsCard";
import { STXPriceConversion } from "@/components/STXPriceConversion";
import {
  formatDateToNow,
  truncateInMiddle,
  createTitleForTransaction,
} from "@/lib";
import { Separator } from "@/components/ui/separator";
import { useMemo } from "react";
import { CopyableText } from "@/components/CopyableText";

function FunctionCard({ transaction }: { transaction: any }) {
  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">
              <FunctionSquare className="h-5 w-5" />
              {transaction?.contract_call?.function_name}
            </div>
            <Badge className="px-1 h-5 text-xs overflow-hidden text-ellipsis max-w-40">
              {transaction?.tx_result?.repr}
            </Badge>
          </div>
        </CardTitle>
      </CardHeader>
      {transaction?.contract_call?.function_args?.length > 0 && (
        <CardContent className="p-5 pt-0 flex flex-col gap-4">
          {transaction?.contract_call?.function_args?.map(
            (arg: any, i: number) => (
              <div className="flex flex-col border rounded-xl" key={i}>
                <div className="flex flex-row grow items-center justify-between gap-4 p-4">
                  <div className="text-sm font-bold w-40 text-ellipsis">
                    {arg.name?.toUpperCase()}
                  </div>
                  <div className="text-sm font-medium text-muted-foreground text-ellipsis max-w-60">
                    {arg.type}
                  </div>
                </div>
                <Separator />
                <div className="text-sm p-4 text-wrap break-words">
                  {arg.repr}
                </div>
              </div>
            )
          )}
        </CardContent>
      )}
    </Card>
  );
}

export function TransactionPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { transaction } = useTransaction({
    hash: id ?? "",
  });
  const type: string = useMemo(() => {
    switch (transaction?.tx_type) {
      case "contract_call":
        return "Contract Call";
      case "coinbase":
        return "Coinbase";
      case "smart_contract":
        return "Contract Deploy";
      case "poison_microblock":
        return "Poison Microblock";
      case "token_transfer":
        return "Token Transfer";
      default:
        return "Unknown Transaction Type";
    }
  }, [transaction]);
  const transferAmount =
    Number(transaction?.token_transfer?.amount) / 1_000_000;
  const feeAmount = Number(transaction?.fee_rate) / 1_000_000;
  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        pre={
          <>
            {transaction?.tx_status === "success" && <Badge>Success</Badge>}
            {transaction?.tx_status === "pending" && <Badge>Pending</Badge>}
            {transaction?.tx_status !== "success" &&
              transaction?.tx_status !== "pending" && (
                <Badge className="bg-destructive">Failed</Badge>
              )}
            <Badge>{type}</Badge>
          </>
        }
        title={transaction && createTitleForTransaction(transaction)}
        accessory={
          transaction && formatDateToNow(transaction?.burn_block_time_iso)
        }
      />
      <div className="flex flex-col grow gap-4 justify-center">
        <div className="flex flex-col lg:flex-row gap-4">
          <Card className="flex flex-col overflow-hidden grow">
            <CardHeader>
              <CardTitle>Summary</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="HASH"
                value={
                  <CopyableText
                    text={transaction?.tx_id}
                    displayText={
                      transaction?.tx_id &&
                      truncateInMiddle(transaction?.tx_id, 16)
                    }
                  />
                }
              />
              {transaction?.tx_type === "token_transfer" && (
                <InfoCardCell
                  title="AMOUNT"
                  value={
                    <>
                      {`${Number(transferAmount)} STX `}
                      <STXPriceConversion
                        amount={transferAmount}
                        className="text-xs font-medium text-muted-foreground"
                      />
                    </>
                  }
                />
              )}
              {transaction?.tx_type === "token_transfer" && (
                <InfoCardCell
                  title="RECIPIENT"
                  value={
                    <CopyableText
                      text={transaction?.token_transfer?.recipient_address}
                      displayText={
                        transaction?.token_transfer?.recipient_address &&
                        truncateInMiddle(
                          transaction.token_transfer.recipient_address,
                          16
                        )
                      }
                      onClick={() =>
                        navigate(
                          `/accounts/${transaction?.token_transfer?.recipient_address}`
                        )
                      }
                    />
                  }
                />
              )}
              {transaction?.tx_type === "contract_call" && (
                <InfoCardCell
                  title="CONTRACT"
                  value={
                    <CopyableText
                      text={transaction?.contract_call?.contract_id}
                      displayText={
                        transaction?.contract_call?.contract_id &&
                        truncateInMiddle(
                          transaction.contract_call.contract_id,
                          16
                        )
                      }
                      onClick={() =>
                        navigate(
                          `/accounts/${transaction?.contract_call?.contract_id}`
                        )
                      }
                    />
                  }
                />
              )}
              {transaction?.tx_type === "smart_contract" && (
                <InfoCardCell
                  title="CONTRACT"
                  value={
                    <CopyableText
                      text={transaction?.smart_contract?.contract_id}
                      displayText={
                        transaction?.smart_contract?.contract_id &&
                        truncateInMiddle(
                          transaction.smart_contract.contract_id,
                          16
                        )
                      }
                      onClick={() =>
                        navigate(
                          `/accounts/${transaction?.smart_contract?.contract_id}`
                        )
                      }
                    />
                  }
                />
              )}
              <InfoCardCell
                title={
                  transaction?.tx_type === "token_transfer"
                    ? "SENDER"
                    : "CALLER"
                }
                value={
                  <CopyableText
                    text={transaction?.sender_address}
                    displayText={
                      transaction?.sender_address &&
                      truncateInMiddle(transaction.sender_address, 16)
                    }
                    onClick={() =>
                      navigate(`/accounts/${transaction?.sender_address}`)
                    }
                  />
                }
              />
              <InfoCardCell
                title="NONCE"
                value={transaction?.nonce?.toLocaleString()}
              />
              <InfoCardCell
                title="FEE"
                value={
                  <>
                    {`${Number(feeAmount)} STX `}
                    <STXPriceConversion
                      amount={feeAmount}
                      className="text-xs font-medium text-muted-foreground"
                    />
                  </>
                }
              />
            </CardContent>
            {(transaction?.smart_contract || transaction?.contract_call) && (
              <CardFooter className="p-0">
                <Button
                  className="flex-1 rounded-none uppercase"
                  onClick={() =>
                    navigate(
                      `/accounts/${
                        transaction?.smart_contract?.contract_id ??
                        transaction?.contract_call?.contract_id
                      }`
                    )
                  }
                >
                  View Contract <ArrowRight className="ml-1 h-5 w-5" />
                </Button>
              </CardFooter>
            )}
          </Card>
          <Card className="flex flex-col overflow-hidden lg:w-1/3">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                Block
                <Blocks className="text-[#5546FF] h-5 w-5" />
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="HEIGHT"
                value={
                  <CopyableText
                    text={transaction?.block_height}
                    displayText={`#${transaction?.block_height?.toLocaleString()}`}
                  />
                }
              />
              <InfoCardCell
                title="HASH"
                value={
                  <CopyableText
                    text={transaction?.block_hash}
                    displayText={
                      transaction?.block_hash &&
                      truncateInMiddle(transaction?.block_hash, 16)
                    }
                  />
                }
              />
            </CardContent>
            <CardFooter className="p-0">
              <Button
                className="flex-1 rounded-none uppercase bg-[#5546FF]"
                onClick={() => navigate(`/blocks/${transaction?.block_height}`)}
              >
                View Block <ArrowRight className="ml-1 h-5 w-5" />
              </Button>
            </CardFooter>
          </Card>
        </div>
        {transaction?.event_count > 0 && (
          <TransactionEventsCard transaction={transaction} />
        )}
        {transaction?.tx_type === "contract_call" && (
          <FunctionCard transaction={transaction} />
        )}
      </div>
    </div>
  );
}
