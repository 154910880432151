import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { TransactionList } from "@/components/TransactionList";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { EventList } from "@/components/EventList";
import { AccountIncomingTransferList } from "@/components/AccountIncomingTransferList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useParams } from "react-router-dom";
import {
  useAccountBalances,
  useAccountNonces,
  useSmartContract,
} from "@/hooks";
import { SourceCodeCard } from "@/components/SourceCode";
import { CopyableText } from "@/components/CopyableText";
import { truncateInMiddle } from "@/lib";
import { STXPriceConversion } from "@/components/STXPriceConversion";
import { FTHoldingRow } from "@/components/FTHoldingRow";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SkeletonRow } from "@/components/SkeletonRow";

function AddressInfoCard({
  id,
  stxBalance,
  stxReceived,
  stxSent,
  numberOfTokens,
  numberOfNFTs,
  totalMinerRewards,
}: any) {
  const { data } = useAccountNonces({ address: id ?? "" });
  return (
    <Card className="overflow-hidden w-full">
      <CardHeader>
        <CardTitle>Summary</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <InfoCardCell
          title="ADDRESS"
          value={
            <CopyableText
              text={id}
              displayText={id && truncateInMiddle(id, 16)}
            />
          }
        />
        <InfoCardCell
          title="STX BALANCE"
          value={
            <>
              {`${stxBalance} STX `}
              <STXPriceConversion
                amount={stxBalance}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="NUMBER OF TOKENS"
          value={numberOfTokens?.toLocaleString()}
        />
        <InfoCardCell
          title="NUMBER OF NFTS"
          value={numberOfNFTs?.toLocaleString()}
        />
        <InfoCardCell
          title="TOTAL STX RECEIVED"
          value={
            <>
              {`${stxReceived} STX `}
              <STXPriceConversion
                amount={stxReceived}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="TOTAL STX SENT"
          value={
            <>
              {`${stxSent} STX `}
              <STXPriceConversion
                amount={stxSent}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="TOTAL MINER REWARDS"
          value={
            <>
              {`${totalMinerRewards} STX `}
              <STXPriceConversion
                amount={totalMinerRewards}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="LAST NONCE USED"
          value={data?.last_executed_tx_nonce?.toLocaleString()}
        />
      </CardContent>
    </Card>
  );
}

function ContractInfoCard({
  id,
  stxBalance,
  stxReceived,
  stxSent,
  numberOfTokens,
  numberOfNFTs,
  contract,
}: any) {
  const navigate = useNavigate();
  const deployer = useMemo(() => {
    const parts = id?.split(".") ?? [];
    return parts[0];
  }, [id]);
  return (
    <Card className="overflow-hidden w-full">
      <CardHeader>
        <CardTitle>Summary</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <InfoCardCell
          title="CONTRACT ID"
          value={
            <CopyableText
              text={contract?.contract_id}
              displayText={
                contract?.contract_id &&
                truncateInMiddle(contract?.contract_id, 16)
              }
            />
          }
        />
        <InfoCardCell
          title="STX BALANCE"
          value={
            <>
              {`${stxBalance} STX `}
              <STXPriceConversion
                amount={stxBalance}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="NUMBER OF TOKENS"
          value={numberOfTokens?.toLocaleString()}
        />
        <InfoCardCell
          title="NUMBER OF NFTS"
          value={numberOfNFTs?.toLocaleString()}
        />
        <InfoCardCell
          title="TOTAL STX RECEIVED"
          value={
            <>
              {`${stxReceived} STX `}
              <STXPriceConversion
                amount={stxReceived}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="TOTAL STX SENT"
          value={
            <>
              {`${stxSent} STX `}
              <STXPriceConversion
                amount={stxSent}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="DEPLOYED BY"
          value={
            <CopyableText
              text={deployer}
              displayText={truncateInMiddle(deployer, 16)}
              onClick={() => navigate(`/accounts/${deployer}`)}
            />
          }
        />
        <InfoCardCell
          title="DEPLOY TRANSACTION"
          value={
            <CopyableText
              text={contract?.tx_id}
              displayText={
                contract?.tx_id && truncateInMiddle(contract?.tx_id, 16)
              }
              onClick={() => navigate(`/transactions/${contract?.tx_id}`)}
            />
          }
        />
      </CardContent>
      <CardFooter className="p-0">
        <Button
          className="flex-1 rounded-none uppercase"
          onClick={() => navigate(`/transactions/${contract?.tx_id}`)}
        >
          View Deploy Transaction <ArrowRight className="ml-1 h-5 w-5" />
        </Button>
      </CardFooter>
    </Card>
  );
}

export function AccountPage() {
  const { id } = useParams();
  const { balances, loading } = useAccountBalances({ address: id ?? "" });
  const { contract } = useSmartContract({ id: id ?? "" });
  const isContract = (id?.indexOf(".") ?? -1) > -1;
  const publicFunctions = useMemo(() => {
    if (!isContract) {
      return [];
    }
    const abi = JSON.parse(contract?.abi ?? '{"functions":[]}');
    return abi.functions.filter(({ access }: any) => access === "public");
  }, [contract, isContract]);

  const stxBalance = Number(balances?.stx.balance) / 1_000_000;
  const stxReceived = Number(balances?.stx.total_received) / 1_000_000;
  const stxSent = Number(balances?.stx.total_sent) / 1_000_000;
  const totalMinerRewards =
    Number(balances?.stx.total_miner_rewards_received) / 1_000_000;
  const numberOfTokens = useMemo(() => {
    return Object.entries(balances?.fungible_tokens ?? {}).filter(
      ([key, value]: any[]) => Number(value?.balance) > 0
    ).length;
  }, [balances]);
  const numberOfNFTs = useMemo(() => {
    return Object.entries(balances?.non_fungible_tokens ?? {}).length;
  }, [balances]);
  const fungibleTokenBalances = Object.entries(balances?.fungible_tokens ?? {});

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader title={`${id && truncateInMiddle(id, 20)}`} />
      {/*args?.map((arg: any) => (
        <TestRecursiveFnArg {...arg} />
      ))*/}
      {isContract && (
        <ContractInfoCard
          id={id}
          stxBalance={stxBalance}
          stxReceived={stxReceived}
          stxSent={stxSent}
          numberOfTokens={numberOfTokens}
          numberOfNFTs={numberOfNFTs}
          contract={contract}
        />
      )}
      {!isContract && (
        <AddressInfoCard
          id={id}
          stxBalance={stxBalance}
          stxReceived={stxReceived}
          stxSent={stxSent}
          numberOfTokens={numberOfTokens}
          numberOfNFTs={numberOfNFTs}
          totalMinerRewards={totalMinerRewards}
        />
      )}
      <Tabs defaultValue="transactions">
        <TabsList className="flex flex-row grow">
          <TabsTrigger value="transactions" className="grow">
            Transactions
          </TabsTrigger>
          <TabsTrigger value="transfers" className="grow">
            Incoming Transfers
          </TabsTrigger>
          {isContract && (
            <TabsTrigger value="events" className="grow">
              Events
            </TabsTrigger>
          )}
          <TabsTrigger value="holdings" className="grow">
            Holdings
          </TabsTrigger>
        </TabsList>
        <TabsContent value="transactions">
          <Card className="overflow-hidden">
            <TransactionList paginate={true} compact={false} principal={id} />
          </Card>
        </TabsContent>
        <TabsContent value="transfers">
          <Card className="overflow-hidden">
            <AccountIncomingTransferList
              paginate={true}
              compact={false}
              principal={id ?? ""}
            />
          </Card>
        </TabsContent>
        <TabsContent value="events">
          <Card className="overflow-hidden">
            <EventList paginate={true} compact={false} contractId={id ?? ""} />
          </Card>
        </TabsContent>
        <TabsContent value="holdings">
          <Card className="overflow-hidden">
            {!loading && fungibleTokenBalances?.length === 0 && (
              <div className="flex w-full p-4 text-center items-center justify-center">
                No Results Found
              </div>
            )}
            {(loading || fungibleTokenBalances?.length > 0) && (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Token Info</TableHead>
                    <TableHead>Contract ID</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {loading
                    ? Array.from({ length: 10 }).map((_, i) => (
                        <SkeletonRow
                          icon={true}
                          title={true}
                          subtitle={true}
                          date={false}
                          otherCols={1}
                          key={i}
                        />
                      ))
                    : null}
                  {!loading &&
                    fungibleTokenBalances
                      .filter(
                        ([key, balance]: any[]) =>
                          Number(balance?.balance ?? 0) > 0
                      )
                      .map(([key, balance]: any[]) => {
                        const principal = key.split("::")[0];
                        return (
                          <FTHoldingRow
                            principal={principal}
                            balance={Number(balance?.balance)}
                            key={key}
                          />
                        );
                      })}
                </TableBody>
              </Table>
            )}
          </Card>
        </TabsContent>
      </Tabs>
      {isContract && (
        <SourceCodeCard clarity={contract?.source_code} abi={contract?.abi} />
      )}
    </div>
  );
}
