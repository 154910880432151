export function createTitleForTransaction(
  transaction: Record<any, any>
): string {
  switch (transaction.tx_type) {
    case "contract_call":
      return transaction.contract_call?.function_name ?? "Function Call";
    case "coinbase":
      return "Coinbase";
    case "smart_contract":
      const contractId = transaction.smart_contract?.contract_id;
      const name = contractId?.split(".")?.[1] ?? "Contract";
      return `Deployed ${name}`;
    case "token_transfer":
      return `Sent ${
        Number(transaction.token_transfer?.amount) / 1_000_000
      } STX`;
    case "poison_microblock":
      return "Poison Microblock";
    default:
      return "Unknown Transaction";
  }
}
