import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useAccountIncomingTransfers } from "@/hooks";
import { TransferRow } from "@/components/TransferRow";
import { DataTablePagination } from "@/components/DataTablePagination";
import { SkeletonRow } from "./SkeletonRow";

export type AccountIncomingTransferListProps = {
  principal: string;
  paginate?: boolean;
  compact?: boolean;
};

export function AccountIncomingTransferList({
  principal,
  paginate,
  compact,
}: AccountIncomingTransferListProps) {
  const {
    transfers,
    total,
    loading,
    nextPage,
    prevPage,
    goToPage,
    changePageSize,
    page,
    pageSize,
  } = useAccountIncomingTransfers({
    address: principal,
  });
  return (
    <div>
      {!loading && transfers?.length === 0 && (
        <div className="flex w-full p-4 text-center items-center justify-center">
          No Results Found
        </div>
      )}
      {(loading || transfers?.length > 0) && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Transfer Info</TableHead>
              <TableHead>Tx Hash</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading
              ? Array.from({ length: 10 }).map((_, i) => (
                  <SkeletonRow
                    icon={true}
                    title={true}
                    subtitle={true}
                    date={false}
                    otherCols={1}
                    key={i}
                  />
                ))
              : null}
            {!loading &&
              transfers.map((transfer: any) => (
                <TransferRow transfer={transfer} compact={compact} />
              ))}
          </TableBody>
        </Table>
      )}
      {paginate && (
        <div className="border-t bg-muted/50 font-medium px-3 py-2">
          <DataTablePagination
            total={total}
            loading={loading}
            page={page}
            pageSize={pageSize}
            setPageSize={changePageSize}
            setPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </div>
      )}
    </div>
  );
}
