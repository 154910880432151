import { truncateInMiddle } from "@/lib/truncateInMiddle";
import {
  Blocks,
  Receipt,
  PersonStanding,
  Code,
  LucideIcon,
} from "lucide-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";

export type SearchItemProps = {
  entity: any;
};

export function SearchItem({ entity }: SearchItemProps) {
  const navigate = useNavigate();
  const title = useMemo(() => {
    if (entity.entity_type === "block_hash") {
      return entity.metadata?.height
        ? `#${Number(entity.metadata.height).toLocaleString()}`
        : truncateInMiddle(entity?.entity_id || "", 10);
    } else {
      return truncateInMiddle(entity?.entity_id || "", 10);
    }
  }, [entity]);
  const Icon: LucideIcon = useMemo(() => {
    if (entity.entity_type === "block_hash") {
      return Blocks;
    } else if (entity.entity_type === "contract_address") {
      return Code;
    } else if (entity.entity_type === "standard_address") {
      return PersonStanding;
    } else {
      return Receipt;
    }
  }, [entity]);
  return (
    <div className="bg-white text-sm p-4 flex flex-row">
      <div className="p-2 bg-black rounded-lg inline-block">
        <Icon color="#FFF" className="h-5 w-5" />
      </div>
      <div className="ml-4 flex flex-col items-start justify-center">
        <Button
          className="font-bold text-sm p-0 h-auto text-primary"
          variant="link"
          key="title"
          onClick={() => {
            if (entity.entity_type === "block_hash") {
              navigate(`/blocks/${entity.entity_id}`);
            } else if (entity.entity_type === "contract_address") {
              navigate(`/accounts/${entity.entity_id}`);
            } else if (entity.entity_type === "standard_address") {
              navigate(`/accounts/${entity.entity_id}`);
            } else {
              navigate(`/transactions/${entity.entity_id}`);
            }
          }}
        >
          {title}
        </Button>
      </div>
    </div>
  );
}
