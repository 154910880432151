export default {
  defaultToken: "",
  ignoreCase: true,
  tokenPostfix: ".clarity",

  brackets: [
    { open: "{", close: "}", token: "delimiter.curly" },
    { open: "[", close: "]", token: "delimiter.square" },
    { open: "(", close: ")", token: "delimiter.parenthesis" },
    { open: "<", close: ">", token: "delimiter.brackets" },
  ],

  keywords: [
    "define-constant",
    "define-private",
    "define-public",
    "define-read-only",
    "define-data-var",
    "define-fungible-token",
    "define-non-fungible-token",
    "define-trait",
    "use-trait",
    "impl-trait",
    "none",
    "true",
    "false",
    "let",
    "if",
    "ok",
    "err",
    "unwrap!",
    "unwrap-err!",
    "unwrap-panic",
    "unwrap-err-panic",
    "match",
    "try!",
    "asserts!",
    "contract-caller",
    "tx-sender",
    "at-block",
    "get-block-info?",
    "get-burn-block-info?",
  ],

  typeKeywords: [
    "int",
    "uint",
    "bool",
    "principal",
    "string-ascii",
    "string-utf8",
    "buff",
    "optional",
    "response",
    "list",
    "tuple",
  ],

  operators: [
    "+",
    "-",
    "*",
    "/",
    ">=",
    "<=",
    ">",
    "<",
    "and",
    "or",
    "not",
    "is-eq",
    "is-some",
    "is-none",
    "is-ok",
    "is-err",
  ],

  symbols: /[=><!~?:&|+\-*\/\^%]+/,

  builtinFunctions: [
    "as-contract",
    "contract-of",
    "tx-sponsor?",
    "stx-liquid-supply",
    "stx-burn?",
    "stx-transfer?",
    "stx-get-balance",
    "stx-transfer-memo?",
    "print",
    "concat",
    "as-max-len?",
    "to-int",
    "to-uint",
    "to-consensus-buff?",
    "sha256",
    "sha512",
    "sha512/256",
    "keccak256",
    "hash160",
    "secp256k1-recover?",
    "secp256k1-verify",
    "normalize-principal",
    "principal-construct?",
    "principal-destruct?",
    "ft-get-balance",
    "ft-get-supply",
    "ft-transfer?",
    "nft-get-owner?",
    "nft-mint?",
    "nft-transfer?",
    "nft-burn?",
    "default-to",
    "merge",
    "get",
    "set",
    "map-get?",
    "map-set",
    "map-insert",
    "map-delete",
    "tuple",
    "get-block-height",
    "is-in-regtest",
    "get-burn-block-height",
    "get-burn-header-hash?",
  ],

  tokenizer: {
    root: [
      { include: "@comment" },
      { include: "@whitespace" },
      { include: "@numbers" },
      { include: "@strings" },
      { include: "@specialTokens" },

      [
        /^(\s*)([a-zA-Z][\w\-\!]*)/,
        {
          cases: {
            "$2@keywords": [
              "keyword",
              { token: "keyword.$2", next: "@blockExpression.$2" },
            ],
            "$2@builtinFunctions": "type.identifier",
            "$2@typeKeywords": "keyword.type",
            "@default": "identifier",
          },
        },
      ],

      [
        /[a-zA-Z][\w\-\!]*/,
        {
          cases: {
            "@keywords": "keyword",
            "@builtinFunctions": "type.identifier",
            "@typeKeywords": "keyword.type",
            "@default": "identifier",
          },
        },
      ],

      [/[{}()\[\]]/, "@brackets"],
      [/[<>](?!@symbols)/, "@brackets"],
      [
        /@symbols/,
        {
          cases: {
            "@operators": "operator",
            "@default": "",
          },
        },
      ],
    ],

    blockExpression: [
      [
        /[a-zA-Z][\w\-\!]*/,
        {
          cases: {
            "@keywords": { token: "keyword.$S2", next: "@pop" },
            "@default": { token: "@rematch", next: "@pop" },
          },
        },
      ],
      ["", "", "@pop"],
    ],

    comment: [[/;.*/, "comment"]],

    whitespace: [[/[ \t\r\n]+/, "white"]],

    numbers: [
      [/0[xX][0-9a-fA-F]+/, "number.hex"],
      [/\d+/, "number"],
    ],

    strings: [
      [/"([^"\\]|\\.)*$/, "string.invalid"],
      [/"/, { token: "string.quote", bracket: "@open", next: "@string" }],
    ],

    string: [
      [/[^\\"]+/, "string"],
      [/"/, { token: "string.quote", bracket: "@close", next: "@pop" }],
    ],

    specialTokens: [
      [/\\'[0-9A-Z]{28,41}(\.[a-zA-Z][\w\-]*){0,2}/, "string.principal"],
      [/(\.[a-zA-Z][\w\-]*){1,2}/, "string.principal"],
    ],
  },
};
