import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface ContractEventsProps extends PaginationOptions {
  contractId: string;
}

export function useContractEvents(props: ContractEventsProps) {
  const { smartContractsApi } = useContext(StacksApiContext)!;
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    smartContractsApi.getContractEventsById.bind(smartContractsApi),
    [
      {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        contractId: props.contractId,
      },
    ]
  );

  return {
    events: data?.results || [],
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
