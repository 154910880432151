import { Card } from "@/components/ui/card";

import { PageContentHeader } from "@/components/PageContentHeader";
import { TokenList } from "@/components/TokenList";

export function TokensPage() {
  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader title="Tokens" />
      <Card className="overflow-hidden">
        <TokenList paginate={true} compact={false} />
      </Card>
    </div>
  );
}
