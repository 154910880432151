import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface AccountNoncesProps {
  address: string;
}

export function useAccountNonces(props: AccountNoncesProps) {
  const { accountsApi } = useContext(StacksApiContext)!;

  const accountNoncesState = useApi(
    accountsApi.getAccountNonces.bind(accountsApi),
    [
      {
        principal: props.address,
        unanchored: true,
      },
    ]
  );

  return accountNoncesState;
}
