import { Coins } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { truncateInMiddle } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";

export type TokenRowProps = {
  token: any;
  compact?: boolean;
  className?: string;
};

export function TokenRow({ token, compact = false, className }: TokenRowProps) {
  const navigate = useNavigate();
  return (
    <CommonRow
      icon={Coins}
      title={`${token?.name} (${token?.symbol})`}
      subtitle={token?.description}
      className={className}
      onClick={() => navigate(`/accounts/${token?.contract_principal}`)}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={token.contract_principal}
            displayText={
              token?.contract_principal &&
              truncateInMiddle(token.contract_principal, 10)
            }
          />
        </Badge>
      </CenteredRowCell>
    </CommonRow>
  );
}
