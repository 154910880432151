import { NavLink, useNavigate } from "react-router-dom";
import { Wallet2, Menu } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Search } from "@/components/Search";

import { AppConfig, UserSession, showConnect } from "@stacks/connect";
import { useInfo } from "@/hooks";

const appConfig = new AppConfig(["store_write", "publish_data"]);
const userSession = new UserSession({ appConfig });

const myAppName = "STX Scan"; // shown in wallet pop-up
const myAppIcon = window.location.origin + "/stxscan.png"; // shown in wallet pop-up

function NavigationList() {
  return (
    <>
      <NavLink
        to="/blocks"
        className={({ isActive, isPending }) =>
          isActive
            ? "text-foreground transition-colors hover:text-foreground"
            : "text-muted-foreground transition-colors hover:text-foreground"
        }
      >
        Blocks
      </NavLink>
      <NavLink
        to="/transactions"
        className={({ isActive, isPending }) =>
          isActive
            ? "text-foreground transition-colors hover:text-foreground"
            : "text-muted-foreground transition-colors hover:text-foreground"
        }
      >
        Transactions
      </NavLink>
      <NavLink
        to="/tokens"
        className={({ isActive, isPending }) =>
          isActive
            ? "text-foreground transition-colors hover:text-foreground"
            : "text-muted-foreground transition-colors hover:text-foreground"
        }
      >
        Tokens
      </NavLink>
    </>
  );
}

export function Header() {
  const connected = userSession.isUserSignedIn();
  const navigate = useNavigate();
  const { info, loading, error } = useInfo();

  const online = info?.burn_block_height && !error;

  function connect() {
    showConnect({
      userSession,
      appDetails: {
        name: myAppName,
        icon: myAppIcon,
      },
      onFinish: () => {
        window.location.reload();
      },
      onCancel: () => {},
    });
    // todo: use recoil for this?
  }

  return (
    <div className="container flex items-center px-4 h-16 justify-between">
      <p
        className="flex flex-row items-center gap-2 font-bold"
        onClick={() => navigate("/")}
      >
        <img src="../stxscan.png" alt="STX Scan" className="h-8 w-auto" />
        STX Scan
      </p>
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <NavigationList />
      </nav>
      <Search className="max-w-sm hidden md:flex md:w-96" />
      <Badge variant="outline">
        {online && (
          <span className="relative flex h-2 w-2 mr-1">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#5546FF] opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-[#5546FF]"></span>
          </span>
        )}
        {!online && !loading && (
          <span className="relative flex h-2 w-2 mr-1">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-destructive opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-destructive"></span>
          </span>
        )}
        {online ? "ONLINE" : loading ? "LOADING" : "OFFLINE"}
      </Badge>
      <Button
        variant={connected ? "default" : "outline"}
        size="icon"
        className="hidden md:flex"
        onClick={connect}
      >
        <Wallet2 />
      </Button>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium py-10">
            <Search />
            <NavigationList />
            <Button
              variant={connected ? "default" : "outline"}
              className="flex w-full md:hidden"
              onClick={connect}
            >
              <Wallet2 className="h-5 w-5 mr-2" />
              {connected ? "Connected" : "Connect Wallet"}
            </Button>
          </nav>
        </SheetContent>
      </Sheet>
    </div>
  );
}
