import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface GetTransactionProps {
  hash: string;
}

export function useTransaction(props: GetTransactionProps) {
  const { transactionsApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    transactionsApi.getTransactionById.bind(transactionsApi),
    [
      {
        txId: props.hash,
      },
    ]
  );

  return {
    transaction: data,
    loading,
    error,
  };
}
