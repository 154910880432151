import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface FTMetadataProps {
  principal: string;
}

export function useFTMetadata({ principal }: FTMetadataProps) {
  const { tokensApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    tokensApi.getFtMetadata.bind(tokensApi),
    [principal]
  );

  return {
    ft: data,
    loading,
    error,
  };
}
