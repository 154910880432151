import { ArrowRight, Bitcoin } from "lucide-react";
import { useParams } from "react-router-dom";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useBlock } from "@/hooks/useBlock";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { CopyableText } from "@/components/CopyableText";
import { truncateInMiddle, formatDateToNow } from "@/lib";
import { TransactionList } from "@/components/TransactionList";

export function BlockPage() {
  const { id } = useParams();
  const { block, loading } = useBlock({
    heightOrHash: id ?? "",
  });
  if (loading) {
    return <div />;
  }

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        title={`Block #${block?.height.toLocaleString()}`}
        accessory={block && formatDateToNow(block?.burn_block_time_iso)}
      />
      <div className="flex flex-col grow gap-4 justify-center">
        <div className="flex flex-col lg:flex-row gap-4">
          <Card className="flex flex-col overflow-hidden grow">
            <CardHeader>
              <CardTitle>Summary</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="HEIGHT"
                value={
                  <CopyableText
                    text={block?.height}
                    displayText={`#${block?.height.toLocaleString()}`}
                  />
                }
              />
              <InfoCardCell
                title="HASH"
                value={
                  <CopyableText
                    text={block?.hash}
                    displayText={
                      block?.hash && truncateInMiddle(block?.hash, 16)
                    }
                  />
                }
              />
              <InfoCardCell
                title="NUMBER OF TXS"
                value={(block?.txs?.length ?? 0)?.toLocaleString()}
              />
              <InfoCardCell
                title="READ OPERATIONS"
                value={`${block?.execution_cost_read_count?.toLocaleString()} (${block?.execution_cost_read_length?.toLocaleString()} bytes)`}
              />
              <InfoCardCell
                title="WRITE OPERATIONS"
                value={`${block?.execution_cost_write_count?.toLocaleString()} (${block?.execution_cost_write_length?.toLocaleString()} bytes)`}
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col overflow-hidden lg:w-1/3">
            <CardHeader>
              <CardTitle className="flex justify-between items-center">
                BTC Burn Block
                <Bitcoin className="text-[#FC6433] h-5 w-5" />
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-2 grow">
              <InfoCardCell
                title="HEIGHT"
                value={
                  <CopyableText
                    text={block?.burn_block_height}
                    displayText={`#${block?.burn_block_height?.toLocaleString()}`}
                  />
                }
              />
              <InfoCardCell
                title="HASH"
                value={
                  <CopyableText
                    text={block?.burn_block_hash}
                    displayText={
                      block && truncateInMiddle(block?.burn_block_hash, 16)
                    }
                  />
                }
              />
              <InfoCardCell
                title="MINER REWARD TX"
                value={
                  <CopyableText
                    text={block?.miner_txid}
                    displayText={
                      block && truncateInMiddle(block?.miner_txid, 16)
                    }
                  />
                }
              />
            </CardContent>
            <CardFooter className="p-0">
              <Button
                className="flex-1 rounded-none uppercase bg-[#FC6433]"
                onClick={() =>
                  window
                    .open(
                      `https://mempool.space/block/${block?.burn_block_height}`,
                      "_blank"
                    )
                    ?.focus()
                }
              >
                View BTC Block <ArrowRight className="ml-1 h-5 w-5" />
              </Button>
            </CardFooter>
          </Card>
        </div>
        <Card className="flex flex-col overflow-hidden grow">
          <CardContent className="p-0">
            <TransactionList
              compact={false}
              paginate={true}
              blockHash={block?.hash}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
