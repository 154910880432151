import { Transition } from "@headlessui/react";

import { cn } from "@/lib/utils";

import { Skeleton } from "@/components/ui/skeleton";
import { TableRow } from "@/components/ui/table";

import { CenteredRowCell } from "@/components/CenteredRowCell";

export type SkeletonRowProps = {
  className?: string;
  title?: boolean;
  subtitle?: boolean;
  icon?: boolean;
  date?: boolean;
  otherCols?: number;
};

export function SkeletonRow({
  className,
  title,
  subtitle,
  icon,
  date,
  otherCols,
}: SkeletonRowProps) {
  return (
    <Transition
      show={true}
      appear={true}
      as={TableRow}
      className={cn(["bg-white", className])}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <CenteredRowCell key="icon">
        {icon && <Skeleton className="h-9 w-9 rounded-lg inline-block" />}
        <div className="ml-4 gap-2 flex flex-col items-start justify-center">
          {title && <Skeleton className="h-3 w-36" key="title" />}
          {subtitle && <Skeleton className="h-2 w-32" key="subtitle" />}
        </div>
      </CenteredRowCell>
      {otherCols &&
        otherCols > 0 &&
        Array.from({ length: otherCols }).map((_, i) => (
          <CenteredRowCell key={`other-col-${i}`}>
            <Skeleton className="flex h-3 max-w-20 grow" />
          </CenteredRowCell>
        ))}
      {date && (
        <CenteredRowCell
          className="text-muted-foreground font-medium text-xs"
          key="date"
        >
          <div className="flex grow flex-direction-row justify-end">
            <Skeleton className="flex h-3 max-w-20 grow" />
          </div>
        </CenteredRowCell>
      )}
    </Transition>
  );
}
