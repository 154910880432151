import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export interface AccountBalancesProps {
  address: string;
  untilBlock?: string;
}

export function useAccountBalances(props: AccountBalancesProps) {
  const { accountsApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    accountsApi.getAccountBalance.bind(accountsApi),
    [
      {
        principal: props.address,
        unanchored: true,
        until_block: props?.untilBlock,
      },
    ]
  );

  return {
    balances: data,
    loading,
    error,
  };
}
