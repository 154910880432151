import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Lock,
  ScrollText,
  Activity,
  Flame,
  ArrowRightLeft,
  Coins,
  LucideIcon,
  ArrowRight,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { CommonRow } from "@/components/CommonRow";
import { useFTMetadata } from "@/hooks/useFTMetadata";
import { useNFTMetadata } from "@/hooks/useNFTMetadata";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CopyableText } from "./CopyableText";
import { truncateInMiddle } from "@/lib";

export type EventRowProps = {
  event: Record<any, any>;
  compact?: boolean;
  className?: string;
};

function createAssetTitle(
  assetEventType: string,
  unit: string,
  amount?: string
) {
  switch (assetEventType) {
    case "mint":
      return `Minted ${amount ?? 1} ${unit}`;
    case "burn":
      return `Burned ${amount ?? 1} ${unit}`;
    case "transfer":
      return `Transferred ${amount ?? 1} ${unit}`;
    default:
      return "Unknown Event";
  }
}

function FTAssetTitle({ event }: EventRowProps) {
  const [ftId, name] = event?.asset?.asset_id?.split("::") ?? [];
  const { ft, loading } = useFTMetadata({
    principal: ftId,
  });
  let amount = Number(event?.asset?.amount) ?? 1;
  if (!loading && !ft) {
    return (
      <div>
        {createAssetTitle(event?.asset?.asset_event_type ?? "unknown", name)}
      </div>
    );
  }
  const decimalDiv = Math.pow(10, ft?.decimals ?? 0);
  amount = Number(amount / decimalDiv);
  return (
    <div>
      {createAssetTitle(
        event?.asset?.asset_event_type ?? "unknown",
        ft?.symbol ?? name,
        `${amount}`
      )}
    </div>
  );
}

function NFTAssetTitle({ event }: EventRowProps) {
  const [nftId, name] = event?.asset?.asset_id?.split("::") ?? [];
  const { nft, loading } = useNFTMetadata({
    principal: nftId,
    tokenId: event?.asset?.token_id,
  });
  if (!loading && !nft) {
    return (
      <div>
        {createAssetTitle(event?.asset?.asset_event_type ?? "unknown", name)}
      </div>
    );
  }
  return (
    <div>
      {createAssetTitle(
        event?.asset?.asset_event_type ?? "unknown",
        nft?.symbol ?? name,
        "1"
      )}
    </div>
  );
}

export function EventRow({ event, compact = false, className }: EventRowProps) {
  const navigate = useNavigate();
  const icon: LucideIcon = useMemo(() => {
    switch (event.event_type) {
      case "stx_lock":
        return Lock;
      case "stx_asset":
      case "fungible_token_asset":
      case "non_fungible_token_asset":
        switch (event.asset.asset_event_type) {
          case "mint":
            return Coins;
          case "burn":
            return Flame;
          case "transfer":
            return ArrowRightLeft;
          default:
            return Activity;
        }
      case "smart_contract_log":
        return ScrollText;
      default:
        return Activity;
    }
  }, [event]);
  const title = useMemo(() => {
    const asset = event.asset;
    switch (event.event_type) {
      case "stx_asset":
        const amount = Number(asset?.amount ?? 0) / 1_000_000;
        return (
          <div>
            {createAssetTitle(
              asset?.asset_event_type ?? "unknown",
              "STX",
              `${amount}`
            )}
          </div>
        );
      case "stx_lock":
        const lock = event.stx_lock_event;
        const lockAmount = Number(lock?.locked_amount ?? 0) / 1_000_000;
        return `${lockAmount} STX Locked`;
      case "fungible_token_asset":
        return <FTAssetTitle event={event} />;
      case "non_fungible_token_asset":
        return <NFTAssetTitle event={event} />;
      case "smart_contract_log":
        return "Smart Contract Log";
      default:
        return "Unknown Event Type";
    }
  }, [event]);
  const subtitle = useMemo(() => {
    const asset = event.asset;
    switch (event.event_type) {
      case "stx_lock":
        const lock = event.stx_lock_event;
        return `Unlocks at ${lock?.unlock_height} height`;
      case "stx_asset":
      case "fungible_token_asset":
      case "non_fungible_token_asset":
        return (
          <div className="flex flex-row items-center gap-1 text-xs font-medium text-muted-foreground">
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              onClick={() => navigate(`/accounts/${asset?.sender || ""}`)}
            >
              {truncateInMiddle(asset?.sender || "", 10)}
            </Button>
            <ArrowRight className="h-3 w-3" />
            <Button
              variant="link"
              className="h-auto w-auto text-xs p-0 font-medium text-muted-foreground"
              onClick={() => navigate(`/accounts/${asset?.recipient || ""}`)}
            >
              {truncateInMiddle(asset?.recipient || "", 10)}
            </Button>
          </div>
        );
      case "smart_contract_log":
        return `Topic: ${event.contract_log?.topic}`;
      default:
        return undefined;
    }
  }, [event]);

  return (
    <CommonRow
      icon={icon}
      title={title}
      subtitle={subtitle}
      className={className}
    >
      {!compact && event.event_type === "stx_lock" && (
        <CenteredRowCell className="hidden sm:table-cell">
          <Badge variant="outline" className="font-medium gap-1 monospaced">
            <CopyableText
              text={event.stx_lock_event?.locked_address}
              displayText={
                event.stx_lock_event?.locked_address &&
                truncateInMiddle(event.stx_lock_event?.locked_address, 10)
              }
              onClick={() =>
                navigate(`/accounts/${event.stx_lock_event?.locked_address}`)
              }
            />
          </Badge>
        </CenteredRowCell>
      )}
      {!compact && event.event_type === "smart_contract_log" && (
        <CenteredRowCell className="hidden sm:table-cell">
          <Badge variant="outline" className="font-medium gap-1 monospaced">
            <CopyableText
              text={event.contract_log?.contract_id}
              displayText={
                event.contract_log?.contract_id &&
                truncateInMiddle(event.contract_log?.contract_id, 10)
              }
              onClick={() =>
                navigate(`/accounts/${event.contract_log?.contract_id}`)
              }
            />
          </Badge>
        </CenteredRowCell>
      )}
      {event.event_type === "smart_contract_log" && (
        <CenteredRowCell>
          <Dialog>
            <DialogTrigger>
              <Button size="sm">View Log</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  Smart Contract Log ({event.contract_log?.topic ?? ""})
                </DialogTitle>
                <DialogDescription>
                  <ScrollArea className="h-[200px] break-all text-wrap mt-4 rounded-lg border p-4">
                    {event.contract_log?.value?.repr}
                  </ScrollArea>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </CenteredRowCell>
      )}
    </CommonRow>
  );
}
