import { useState } from "react";

export interface PaginationOptions {
  initialPage?: number;
  initialPageSize?: number;
}

export type PaginationMetadata = {
  page: number;
  pageSize: number;
};

export type PaginationFns = {
  goToPage: (newPage: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  changePageSize: (newPageSize: number) => void;
};

export function usePagination(
  options?: PaginationOptions
): PaginationMetadata & PaginationFns {
  const [page, setPage] = useState(options?.initialPage || 1);
  const [pageSize, setPageSize] = useState(options?.initialPageSize || 10);

  const goToPage = (newPage: number) => {
    setPage(newPage);
  };

  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return {
    page,
    pageSize,
    goToPage,
    nextPage,
    prevPage,
    changePageSize,
  };
}
