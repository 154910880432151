import { Coins } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { useFTMetadata } from "@/hooks/useFTMetadata";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { truncateInMiddle } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";

export type HoldingRowProps = {
  principal: any;
  balance: number;
  compact?: boolean;
  className?: string;
};

export function FTHoldingRow({
  principal,
  balance,
  compact = false,
  className,
}: HoldingRowProps) {
  const navigate = useNavigate();
  const { ft } = useFTMetadata({
    principal,
  });
  const decimals = ft?.decimals ?? 0;
  const decimalDiv = Math.pow(10, decimals);
  const amount = Number(balance) / decimalDiv;
  const totalSupply = Number(ft?.total_supply ?? 0) / decimalDiv ?? "Unknown";
  return (
    <CommonRow
      icon={Coins}
      title={`${ft?.name ?? "Unknown FT"} (${ft?.symbol ?? "Unknown"})`}
      subtitle={
        <>
          <div>Balance: {amount}</div>
          <div>Total Supply: {totalSupply}</div>
        </>
      }
      className={className}
      onClick={() => navigate(`/accounts/${principal}`)}
    >
      {
        <CenteredRowCell>
          <Badge variant="outline" className="font-medium gap-1 monospaced">
            <CopyableText
              text={principal}
              displayText={truncateInMiddle(principal, 10)}
            />
          </Badge>
        </CenteredRowCell>
      }
    </CommonRow>
  );
}
