import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";
import { usePagination, PaginationOptions } from "./usePagination";

export interface AccountIncomingTransfersProps extends PaginationOptions {
  address: string;
  blockHeight?: string;
  untilBlock?: string;
}

export function useAccountIncomingTransfers(
  props: AccountIncomingTransfersProps
) {
  const { accountsApi } = useContext(StacksApiContext)!;
  const { page, pageSize, ...paginationFns } = usePagination(props);

  const { data, loading, error } = useApi(
    accountsApi.getAccountInbound.bind(accountsApi),
    [
      {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        principal: props.address,
        unanchored: true,
        height: props?.blockHeight,
        until_block: props?.untilBlock,
      },
    ]
  );

  return {
    transfers: data?.results || [],
    total: data?.total ?? 0,
    loading,
    error,
    page,
    pageSize,
    ...paginationFns,
  };
}
