import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";
import { TableCell } from "@/components/ui/table";

export type CenteredRowCellProps = {
  className?: string;
};

export function CenteredRowCell({
  className,
  children,
}: PropsWithChildren<CenteredRowCellProps>) {
  return (
    <TableCell className={cn("p-4", className)}>
      <div className="flex items-center">{children}</div>
    </TableCell>
  );
}
