import { Card } from "@/components/ui/card";

import { PageContentHeader } from "@/components/PageContentHeader";
import { TransactionList } from "@/components/TransactionList";

export function TransactionsPage() {
  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader title="Latest Transactions" />
      <Card className="overflow-hidden">
        <TransactionList paginate={true} compact={false} />
      </Card>
    </div>
  );
}
