import { useState, useEffect } from "react";
import { Copy, Check } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { cn } from "@/lib/utils";

export type CopyableTextProps = {
  displayText: string;
  text: string;
  className?: string;
  onClick?: () => void;
};

export function CopyableText({
  displayText,
  text,
  className,
  onClick,
}: CopyableTextProps) {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  function copy() {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className={cn("flex flex-row items-center gap-1", className)}>
            {onClick ? (
              <Button
                variant="link"
                size="none"
                className={cn("p-0 h-auto text-inherit", className)}
                onClick={onClick}
              >
                {displayText}
              </Button>
            ) : (
              displayText
            )}
            <Button
              variant="ghost"
              size="icon"
              className={cn(
                "p-0 h-3 w-3 aspect-square text-muted-foreground",
                className
              )}
              onClick={copy}
            >
              {copied ? (
                <Check className="aspect-square text-success" />
              ) : (
                <Copy className="aspect-square" />
              )}
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
