import { useContext } from "react";
import { StacksApiContext } from "../context";

import { useApi } from "./useApi";

export function useMempoolTxStats() {
  const { transactionsApi } = useContext(StacksApiContext)!;

  const { data, loading, error } = useApi(
    transactionsApi.getMempoolTransactionStats.bind(transactionsApi)
  );

  return {
    stats: data,
    loading,
    error,
  };
}
