import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody } from "@/components/ui/table";
import { EventRow } from "@/components/EventRow";

export function TransactionEventsCard({ transaction }: { transaction: any }) {
  return (
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle>Events</CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableBody>
            {transaction?.events.map((event: any, i: number) => (
              <EventRow event={event} key={i} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
